import React, { useEffect } from "react";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import { isMobile } from "react-device-detect";
import orange from "./../pics/orange.jpg";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";

export const Download = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MobileView = () => {
    return (
      <div className="background_mobile">
        <div className="edgeline"></div>
        <div className="down_options_mobile">
          <img src={orange} alt="" className="naranja_mobile"></img>
          <div className="middleline"></div>
          <div className="down_buttons_mobile">
            <div className="down_android_mobile">
              <AndroidIcon fontSize="large" style={{ color: "#fff" }} />
              <a href="https://play.google.com/store/apps/details?id=com.ciencioapp">
                <button className="down_button_mobile">
                  <text>{i18n({ index: "download_android", language })}</text>
                </button>
              </a>
            </div>
            <div className="separation_line_mobile"></div>
            <div className="down_apple_mobile">
              <a href="https://apps.apple.com/app/ciencio/id6472603552">
                <button className="down_button_apple_mobile">
                  <text>{i18n({ index: "download_iphone", language })}</text>
                </button>
              </a>
              <AppleIcon fontSize="large" style={{ color: "#fff" }} />
            </div>
          </div>
          <div className="endline"></div>
        </div>
        {/* <div className="download_extra_text">
          <text>Hey kid</text>
        </div> */}
        <div className="bottomline_download_mobile"></div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="background">
        <div className="edgeline"></div>
        <div className="down_options">
          <img src={orange} alt="" className="naranja"></img>
          <div className="down_buttons">
            <div className="down_android">
              <AndroidIcon fontSize="large" style={{ color: "#fff" }} />
              <a href="https://play.google.com/store/apps/details?id=com.ciencioapp">
                <button className="down_button">
                  <text>{i18n({ index: "download_android", language })}</text>
                </button>
              </a>
            </div>
            <div className="separation_line"></div>
            <div className="down_apple">
              <a href="https://apps.apple.com/app/ciencio/id6472603552">
                <button className="down_button_apple">
                  <text>{i18n({ index: "download_iphone", language })}</text>
                </button>
              </a>
              <AppleIcon fontSize="large" style={{ color: "#fff" }} />
            </div>
          </div>
        </div>
        <div className="bottomline_download"></div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
