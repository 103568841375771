import React, { useEffect } from "react";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import { isMobile } from "react-device-detect";
import boy from "./../pics/boy.jpg";
import snail from "./../pics/snail.jpg";
import flower from "./../pics/flower~2.jpg";
import naranja4 from "./../pics/naranja4.jpg";
import { motion } from "framer-motion";

export const Home = () => {
  const [settings] = useUserSettings();
  const { language } = settings;
  // const ref = useRef(null);
  // const { scrollYProgress } = useScroll({ target: ref });
  // const y = useTransform(scrollYProgress, [0, 1], [-300, 350]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MobileView = () => {
    return (
      <motion.div className="App">
        <div className="strategypage">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="strategyrightmobile"
          >
            <img className="strategyimgmobile" src={naranja4} alt=""></img>

            <div className="stextmobile">
              <text className="strategytextmobile">
                {i18n({ index: "ciencio_1", language })}
              </text>
            </div>
          </motion.div>

          <div className="strategyleftmobile">
            <div className="stextmobile">
              <text className="strategytextmobile">
                {i18n({ index: "ciencio_2", language })}
              </text>
            </div>
            <img className="strategyimg" src={snail} alt=""></img>
          </div>

          <div className="strategyright2">
            <img className="strategyimg" src={boy} alt=""></img>
            <div className="stextmobile">
              <text className="strategytextmobile">
                {i18n({ index: "ciencio_3", language })}
              </text>
              <text className="strategytextmobile">
                {i18n({ index: "ciencio_31", language })}
              </text>
              <text className="strategytextmobile">
                {i18n({ index: "ciencio_32", language })}
              </text>
            </div>
          </div>

          <div className="strategyleft2">
            <div className="stextmobile">
              <text className="strategytextmobile">
                {i18n({ index: "ciencio_4", language })}
              </text>
            </div>
            <img className="strategyimg" src={flower} alt=""></img>
          </div>
        </div>
      </motion.div>
    );
  };

  const DesktopView = () => {
    return (
      <motion.div className="App">
        <div className="strategypage">
          <div className="strategyright">
            <motion.img
              className="strategyimg"
              src={naranja4}
              alt=""
            ></motion.img>

            <div className="stext">
              <text className="strategytext">
                {i18n({ index: "ciencio_1", language })}
              </text>
            </div>
          </div>

          <div className="strategyleft">
            <div className="stext">
              <text className="strategytext">
                {i18n({ index: "ciencio_2", language })}
              </text>
            </div>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                duration: 1,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="strategyimg"
              src={snail}
              alt=""
            ></motion.img>
          </div>

          <div className="strategyright2">
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                duration: 1,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="strategyimg"
              src={boy}
              alt=""
            ></motion.img>
            <div className="stext">
              <text className="strategytext">
                {i18n({ index: "ciencio_3", language })}
              </text>
              <text className="strategytext">
                {i18n({ index: "ciencio_31", language })}
              </text>
              <text className="strategytext">
                {i18n({ index: "ciencio_32", language })}
              </text>
            </div>
          </div>

          <motion.div className="strategyleft2">
            <div className="stext">
              <text className="strategytextvertical">
                {i18n({ index: "ciencio_4", language })}
              </text>
            </div>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                duration: 1,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="strategyimg"
              src={flower}
              alt=""
            ></motion.img>
          </motion.div>
        </div>
      </motion.div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
