import React, { useEffect } from "react";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import { isMobile } from "react-device-detect";
import radio from "./../pics/radio.jpg";
import disc from "./../pics/discb.jpg";
import MenuIcon from "@mui/icons-material/Menu";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export const Music = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MobileView = () => {
    return (
      <div className="music_page">
        <div className="music_2_1">
          <img className="music_image" alt="" src={radio}></img>
        </div>
        <div className="music_2_2">
          <div className="music_topline"></div>
          <text className="text_div_music_mobile">
            {i18n({ index: "music_intro", language })}
          </text>
          <text className="text_div_music_mobile">
            {i18n({ index: "listen", language })}
          </text>
          <div className="line"></div>
          <div className="text_div_music_mobile">
            <h2 className="music_title">
              {i18n({ index: "playlist_1", language })}
            </h2>
            <a
              className="none"
              href="https://youtube.com/playlist?list=PL6mXyoS9gT9uo2teAyE3Gs_LpN2eYU6tl&si=UXRTKjLmnNh74DAQ"
            >
              <button className="youtube_button">
                <PlayArrowIcon className="icon_left" />
                <text>{i18n({ index: "youtube", language })}</text>
              </button>
            </a>
            <a
              className="none"
              href="https://open.spotify.com/playlist/6lgJ7I3yMPa7M9UDFUS7UZ?si=31989845b1eb4e69"
            >
              <button className="spotify_button">
                <text>{i18n({ index: "spotify", language })}</text>
                <MenuIcon className="icon_right" />
              </button>
            </a>
          </div>
          <div className="line"></div>
          <div className="text_div_music_mobile">
            <h2 className="music_title_2">
              {i18n({ index: "playlist_2", language })}
            </h2>
            <a
              className="none"
              href="https://youtube.com/playlist?list=PL6mXyoS9gT9vAttq7QoBxwLI5QwNlEPLk&si=5ur-kx2F8cdPiDuS"
            >
              <button className="youtube_button">
                <PlayArrowIcon className="icon_left" />
                <text>{i18n({ index: "youtube", language })}</text>
              </button>
            </a>
            <a
              className="none"
              href="https://open.spotify.com/playlist/6DaqGhMyZqEMDx7kTmJu5L?si=53b4d152447f4a90"
            >
              <button className="spotify_button">
                <text>{i18n({ index: "spotify", language })}</text>
                <MenuIcon className="icon_right" />
              </button>
            </a>
          </div>
          <div className="music_bottomline"></div>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="music_page">
        <div className="music_2_1">
          <img className="music_image" alt="" src={disc}></img>
        </div>
        <div className="music_2_2">
          <div className="music_topline"></div>
          <text className="text_div_music">
            {i18n({ index: "music_intro", language })}
          </text>
          <text className="text_div_music">
            {i18n({ index: "listen", language })}
          </text>
          <div className="line"></div>
          <div className="music_text_div">
            <h2 className="music_title">
              {i18n({ index: "playlist_1", language })}
            </h2>
            <a
              className="none"
              href="https://youtube.com/playlist?list=PL6mXyoS9gT9uo2teAyE3Gs_LpN2eYU6tl&si=UXRTKjLmnNh74DAQ"
            >
              <button className="youtube_button">
                <PlayArrowIcon className="icon_left" />
                <text>{i18n({ index: "youtube", language })}</text>
              </button>
            </a>
            <a
              className="none"
              href="https://open.spotify.com/playlist/6lgJ7I3yMPa7M9UDFUS7UZ?si=31989845b1eb4e69"
            >
              <button className="spotify_button">
                <text>{i18n({ index: "spotify", language })}</text>{" "}
                <MenuIcon className="icon_right" />
              </button>
            </a>
          </div>
          <div className="line"></div>
          <div className="music_text_div_2">
            <h2 className="music_title_2">
              {i18n({ index: "playlist_2", language })}
            </h2>
            <a
              className="none"
              href="https://youtube.com/playlist?list=PL6mXyoS9gT9vAttq7QoBxwLI5QwNlEPLk&si=5ur-kx2F8cdPiDuS"
            >
              <button className="youtube_button">
                <PlayArrowIcon className="icon_left" />
                <text>{i18n({ index: "youtube", language })}</text>
              </button>
            </a>
            <a
              className="none"
              href="https://open.spotify.com/playlist/6DaqGhMyZqEMDx7kTmJu5L?si=53b4d152447f4a90"
            >
              <button className="spotify_button">
                <text>{i18n({ index: "spotify", language })}</text>
                <MenuIcon className="icon_right" />
              </button>
            </a>
          </div>
          <div className="music_bottomline"></div>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
