import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./Components/Home";
import { Four } from "./Components/404";
import { Download } from "./Components/Download";
import { Navigation } from "./Components/Navigation";
import { UserProvider } from "./user";
import { About } from "./Components/About";
import { Footer } from "./Components/Footer";
import { Music } from "./Components/Music";
import { TermsConditions } from "./Components/TermsConditions";
import { Privacy } from "./Components/PrivacyPolicy";
// import { Eula } from "./Components/EULA";

function App() {
  const settings = { language: "en" };
  return (
    <UserProvider settings={settings}>
      <Router>
        <Navigation />
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/download" element={<Download />} />
            <Route path="/music" element={<Music />} />
            <Route path="/termsconditions" element={<TermsConditions />} />
            <Route path="/privacy" element={<Privacy />} />
            {/* <Route path="/eula" element={<Eula />} /> */}
            <Route path="*" element={<Four />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </UserProvider>
  );
}

export default App;
