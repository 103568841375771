export const i18n = ({ index, language }) => {
  const translations = {
    arte_title: {
      en: "Art in Communication",
      es: "Arte en la comunicación",
      fr: "Art dans la communication",
    },
    welcome: {
      en: "Welcome",
      es: "Hola",
      fr: "Salut",
    },
    home: {
      en: "home",
      es: "inicio",
      fr: "accueil",
    },
    about: {
      en: "about",
      es: "nosotros",
      fr: "nous",
    },
    download: {
      en: "download",
      es: "descargar",
      fr: "télécharger",
    },
    music: {
      en: "music",
      es: "música",
      fr: "musique",
    },
    contact: {
      en: "Contact Us:",
      es: "Contactanos:",
      fr: "Contactez-nous:",
    },
    download_android: {
      en: "download ciencio for android",
      es: "descargar ciencio para android",
      fr: "télécharger ciencio pour android",
    },
    download_iphone: {
      en: "download ciencio for iphone",
      es: "descargar ciencio para iphone",
      fr: "télécharger ciencio pour iphone",
    },
    ciencio_1: {
      en: "Ciencio emerges as an alternative, slower-paced communication platform.",
      es: "Ciencio se presenta como una plataforma de comunicación alternativa, con un ritmo más tranquilo.",
      fr: "Ciencio se présente comme une plateforme de communication alternative, à un rythme plus calme.",
    },
    ciencio_2: {
      en: "Get creative and discover the potential of having a conversation with images only.",
      es: "Desafiate y descubrí el potencial que se esconde detrás de un diálogo compuesto solamente de imágenes.",
      fr: "Découvre le potentiel qui se cache derrière une conversation composée uniquement par des images.",
    },
    ciencio_3: {
      en: "Start a chat.",
      es: "Iniciá una conversación.",
      fr: "Commence une discussion.",
    },
    ciencio_31: {
      en: "Play freely.",
      es: "Jugá libremente.",
      fr: "Joue librement.",
    },
    ciencio_32: {
      en: "Create and interact.",
      es: "Creá e interactuá.",
      fr: "Créons ensemble.",
    },
    ciencio_4: {
      en: "Silence can be so beautiful.",
      es: "El silencio puede ser muy bello.",
      fr: "Le silence peut se faire ravissant.",
    },
    about_title: {
      en: "attempt at description",
      es: "intento de descripción",
      fr: "essai de description",
    },
    about_title_2: {
      en: "visual correspondences",
      es: "correspondencias visuales",
      fr: "correspondences visuelles",
    },
    about_title_3: {
      en: "who are we?",
      es: "¿quienes somos?",
      fr: "qui sommes nous?",
    },
    about_text: {
      en: "Ciencio is an app designed for you to explore visual communication through image-only, one-on-one conversations. It can be used for creative or leisure purposes or be transformed into an educational tool. Creation, play and dialogue all in one can make quite a special combination, and each result is a collaborative work of art, open to each individual's interpretation. We invite you to explore and compose worlds of your own!",
      es: "Ciencio es una applicación diseñada para que puedas explorar el lenguage visual abriendo un diálogo compuesto exclusivamente por fotografías. Puede ser usada con fines creativos y lúdicos, así como también transformarse en una herramienta para la educación. La suma de la creatividad, el juego y el diálogo puede producir una combinación muy especial, y cada resultado es una obra de arte compartida, abierta a la interpretación de cada individuo. Es una invitación a componer mundos a tu medida. ¿Te animás a empezar?",
      fr: "Ciencio est une application conçue pour vous permettre l'exploration de la communication visuelle à l'aide d'une discussion à deux soutenue uniquement par des images. Elle peut viser la creativité, le ludique, ainsi que servir comme outil éducatif. Le jeu, la création et le dialogue, unis, font une somme qui surprend, differénte à chaque fois: une œuvre d'art partagée, à déchiffrer librement par l'individu. Jetez-vous à l'eau!",
    },
    about_text_2: {
      en: "«Not words but images as a means of communication. The polysemy of images opens up different courses of action at each crossroads. There is no dictionary for visual dialogue. It does not refer to a previous dialogue, neither is it based on a solid and structured literary tradition. Visual dialogue changes; it moves quickly, at its own pace; its roots plunge into the visual culture of those who enact it. Correspondence passes through fluent and stagnant moments, at times immediate, at times slow, protracted communications. Without losing its playful nature, it passes through difficult moments. There is doubt, provocation, spontaneity. There is surprise, pleasure, frustration. If the author frees himself from the weight of being the main reference in his own creative self and attempts a shared visual construction, photography and the creation of images become similar to musical interpretation.»",
      es: "«No la palabra, sino la imagen como forma de comunicación. Su polisemia admite distintos cursos de acción posibles ante cada encrucijada. El diálogo visual no tiene diccionario. No remite a un diálogo anterior, ni se basa en una tradición sólida y estructurada, literaria. El diálogo visual muta, se sacude con rapidez, tiene un tiempo propio, y raíces en la cultura visual del que lo protagoniza. La correspondencia pasa por momentos fluidos y de estancamiento, de comunicaciones inmediatas y otras lentas, postergadas. Sin perder el carácter lúdico, pasa por momentos difíciles. Hay duda, provocación, espontaneidad. Hay sorpresa, placer, frustración. Si el autor se libera de su Yo creativo como principal referente y ensaya una construcción visual a dos manos, un modo de ver compartido, la fotografía y la creación de imágenes se acercan a la interpretación musical.»",
      fr: "«Non pas les mots, mais les images comme forme de communication. La polysémie des images ouvre à chaque intersection plusieurs cours d'action. Le dialogue visuel no possède pas de dictionnaire. Il ne fais pas référence a un dialogue antérieur ni se base sur une tradition litéraire solide et structurée. Le dialogue visuel est dynamique; il se transforme rapidement et à sa propre vitesse; ses racines plongent dans la culture visuelle de ceux qui le produisent. La correspondance passe par des moments fluides et des moments stagnants; des communications parfois immédiates, parfois lentes et prolongées. Sans perdre sa nature ludique, elle passe par des moments de difficulté. Elle traverse l'incertitude, la provocation, la spontanéité, tout comme la surprise, le plaisir et la frustration. Si l'auteur se libère du poids d'être la principale référence de son identité créative et se prête à une construction visuelle partagée, la photographie et la création d'images se rapprochent à l'interprétation musicale.»",
    },
    about_text_21: {
      en: "- Marcelo Brodsky, Visual Correspondences",
      es: "- Marcelo Brodsky, Correspondencias visuales",
      fr: "- Marcelo Brodsky, Correspondances visuelles",
    },
    about_text_3: {
      en: "We are from Uruguay and we are barely getting started, so if you have any questions or concerns, please let us know!",
      es: "Somos de Uruguay y estamos recién dando nuestros primeros pasos. No dudes en contactarnos; nos encanta conocer tu opinión y tus preguntas.",
      fr: "Nous sommes en Uruguay et nous faisons encore nos premiers pas, donc si vous avez des questions concernant quoi que ce soit, n'hésitez pas à nous écrire!",
    },
    music_intro: {
      en: "We love Ciencio almost just as much as we love music.",
      es: "Nuestro amor por Ciencio es casi tan grande como el que tenemos por la música.",
      fr: "Notre amour envers Ciencio est presque aussi fort que celui que nous avons envers la musique.",
    },
    listen: {
      en: "We wanted to share some of our favourites with you. You can listen to our playlists below. Enjoy :)",
      es: "Queríamos compartir un poco de ella con ustedes. Pueden escuchar nuestras playlists aquí abajo :)",
      fr: "Nous voulions en partager un peu avec vous. Écoutez nos playlists ci-dessous ;)",
    },
    playlist_1: {
      en: "ciencio in four songs",
      es: "ciencio en cuatro canciones",
      fr: "ciencio en quatre chansons",
    },
    playlist_2: {
      en: "core selection",
      es: "«core selection»",
      fr: "«core selection»",
    },
    youtube: {
      en: "listen on youtube",
      es: "escuchar en youtube",
      fr: "écouter sur youtube",
    },
    spotify: {
      en: "listen on spotify",
      es: "escuchar en spotify",
      fr: "écouter sur spotify",
    },
    terms_conditions: {
      en: "Terms and Conditions",
      es: "Términos y Condiciones",
      fr: "Termes et Conditions",
    },
    privacy: {
      en: "Privacy Policy",
      es: "Privacidad",
      fr: "Privacité",
    },
  };

  const translation = translations[index][language];

  return translation;
};

export const Language = () => {};
