import { createContext, useContext, useMemo, useState } from "react";

const UserContext = createContext();

/** @returns {SettingsState} */
export const useUserSettings = () => {
  const { settings } = useContext(UserContext);

  return [settings[0], settings[1]];
};

export const UserProvider = (props) => {
  const [_settings, _setSettings] = useState(props.settings);

  const setSettings = (settings) => {
    _setSettings(settings);
    // storeUserSettings(settings);
  };

  const settings = useMemo(() => [_settings, setSettings], [_settings]);
  return <UserContext.Provider value={{ settings }} {...props} />;
};

/**
 * @typedef {{language: string}} Settings
 * @typedef {[Settings, () => {}]} SettingsState
 */
