import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
// import logo from "./../pics/logo.jpg";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import logo from "./../pics/florblanca.png";

export const Footer = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  const MobileView = () => {
    return (
      <div className="footer_mobile">
        <div className="footer_column_mobile">
          <text className="footer_title">Ciencio</text>
          <Link className="footer_opt" to={"/"}>
            {i18n({ index: "home", language })}
          </Link>
          <Link className="footer_opt" to={"/about"}>
            {i18n({ index: "about", language })}
          </Link>
          <Link className="footer_opt" to={"/download"}>
            {i18n({ index: "download", language })}
          </Link>
          <Link className="footer_opt" to={"/music"}>
            {i18n({ index: "music", language })}
          </Link>
        </div>
        <div className="footer_column_mobile">
          <text className="footer_title">
            {i18n({ index: "contact", language })}
          </text>
          <div className="footer_opt">
            <button className="footer_email">
              <Mailto email="ciencio.app@gmail.com" subject="Ciencio" body="">
                <EmailOutlinedIcon style={{ color: "#fff" }} />
              </Mailto>
              <text className="email_description">ciencio.app@gmail.com</text>
            </button>
          </div>
          <div className="footer_opt">
            <button className="footer_email">
              <a href="https://instagram.com/ciencio_">
                <InstagramIcon style={{ color: "#fff" }} />
              </a>
              <text className="email_description">@ciencio_</text>
            </button>
          </div>
        </div>
        <div className="termsdiv_mobile">
          <Link className="terms" to={"/termsconditions"}>
            {i18n({ index: "terms_conditions", language })}
          </Link>
          <Link className="terms" to={"/privacy"}>
            {i18n({ index: "privacy", language })}
          </Link>
        </div>
        <div className="copyright_mobile">
          <text>Copyright © 2024 Coral Scherb. All rights reserved.</text>
        </div>
        <img src={logo} alt="" className="footer_flower_mobile"></img>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="footer">
        <div className="footer_rows">
          <div className="footer_column">
            <text className="footer_title">Ciencio</text>
            <Link className="footer_opt" to={"/"}>
              {i18n({ index: "home", language })}
            </Link>
            <Link className="footer_opt" to={"/about"}>
              {i18n({ index: "about", language })}
            </Link>
            <Link className="footer_opt" to={"/download"}>
              {i18n({ index: "download", language })}
            </Link>
            <Link className="footer_opt" to={"/music"}>
              {i18n({ index: "music", language })}
            </Link>
          </div>
          <div className="footer_column">
            <text className="footer_title">
              {i18n({ index: "contact", language })}
            </text>
            <div className="footer_opt">
              <button className="footer_email">
                <Mailto email="ciencio.app@gmail.com" subject="Ciencio" body="">
                  <EmailOutlinedIcon style={{ color: "#fff" }} />
                </Mailto>
                <text className="email_description">ciencio.app@gmail.com</text>
              </button>
            </div>
            <div className="footer_opt">
              <button className="footer_email">
                <a href="https://instagram.com/ciencio_">
                  <InstagramIcon style={{ color: "#fff" }} />
                </a>
                <text className="email_description">@ciencio_</text>
              </button>
            </div>
          </div>
          <img src={logo} alt="" className="footer_flower"></img>
        </div>

        <div className="copyright">
          <text>Copyright © 2024 Coral Scherb. All rights reserved.</text>
        </div>
        <div className="termsdiv">
          <Link className="terms" to={"/termsconditions"}>
            {i18n({ index: "terms_conditions", language })}
          </Link>
          <Link className="terms" to={"/privacy"}>
            {i18n({ index: "privacy", language })}
          </Link>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
