import React, { useEffect } from "react";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import { isMobile } from "react-device-detect";
import sunset from "./../pics/sunset.jpg";
// import { Link } from "react-router-dom";

export const About = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MobileView = () => {
    return (
      <div className="ciencio_page_2">
        <div className="page_2_1">
          <div className="edgeline"></div>
          <div className="text_div_mobile">
            <h2 className="about_title">
              {i18n({ index: "about_title", language })}
            </h2>

            <text>{i18n({ index: "about_text", language })}</text>
          </div>
          <div className="line_mobile"></div>
          <div className="text_div_mobile">
            <h2 className="about_title_2">
              {i18n({ index: "about_title_2", language })}
            </h2>

            <text className="quote">
              {i18n({ index: "about_text_2", language })}
            </text>
            <text className="author_mobile">
              {i18n({ index: "about_text_21", language })}
            </text>
          </div>
          <div className="line_mobile"></div>
          <div className="text_div_mobile">
            <h2 className="about_title_3">
              {i18n({ index: "about_title_3", language })}
            </h2>

            <text>{i18n({ index: "about_text_3", language })}</text>
          </div>
          <div className="bottomline"></div>
        </div>
        <div className="page_2_2">
          <img className="about_image" alt="" src={sunset}></img>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="ciencio_page_2">
        <div className="page_2_1">
          <div className="edgeline"></div>
          <div className="text_div">
            <h2 className="about_title">
              {i18n({ index: "about_title", language })}
            </h2>

            <text>{i18n({ index: "about_text", language })}</text>
          </div>
          <div className="line"></div>
          <div className="text_div">
            <h2 className="about_title_2">
              {i18n({ index: "about_title_2", language })}
            </h2>

            <text className="quote">
              {i18n({ index: "about_text_2", language })}
            </text>
            <text className="author">
              {i18n({ index: "about_text_21", language })}
            </text>
          </div>
          <div className="line"></div>
          <div className="text_div">
            <h2 className="about_title_3">
              {i18n({ index: "about_title_3", language })}
            </h2>

            <text>{i18n({ index: "about_text_3", language })}</text>
          </div>
          <div className="bottomline"></div>
        </div>
        <div className="page_2_2">
          <img className="about_image" alt="" src={sunset}></img>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
