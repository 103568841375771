import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

export const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MobileView = () => {
    return (
      <div className="background_2">
        <div className="privacydiv_mobile">
          <h3>Terms and Conditions</h3>
          <text className="privacytext">
            LICENSED APPLICATION END USER LICENSE AGREEMENT
          </text>
          <text className="privacytext">
            {" "}
            <b>i. </b>Ciencio does not tolerate objectionable content or abusive
            users. Child Sexual Abuse and Exploitation is strictly prohibited.
            You cannot use our service to send spam, scam users, or promote
            violence in any way. We also do not allow you to share explicit
            and/or pornographic content on your publicly viewable profile.
          </text>
          <text className="privacytext">
            Were this to happen, please report immediately. Ciencio has the
            right to access and remove any reported content from the app and
            restrain or deny access to users who have been reported, depending
            on the case.
          </text>
          <text className="privacytext">
            <b>ii. </b>
            While we do not demonize consensed private sharing of pornographic
            images, please be mindful and respectful, especially when contacting
            someone you don't know. If you fail to do so, we may block you from
            your account or take required action.
          </text>
          <text className="privacytext">
            If you make your account public and receive harmful images, please
            report to us as soon as you can.
          </text>
          <text className="privacytext">
            We are aware that these things can happen and will make a human
            effort to handle the situation appropriately.
          </text>
          <text className="privacytext">
            <b>iii. </b>
            Your account is private by default. When you have a private account,
            only people who know your contact information can contact you. If
            you choose to have a public profile, anybody who also has a public
            account can reach you. We have done this to allow people to get to
            know each other in a different way. You are responsible for whom you
            chose to contact and the images you share on Ciencio's platform.
          </text>
          <text className="privacytext">
            <b>iv. </b>
            By using Ciencio, you agree to these Terms of Service. If you have
            any questions, kindly contact us via email.
          </text>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="background_2">
        <div className="privacydiv">
          <h3>Terms and Conditions</h3>
          <text className="privacytext">
            LICENSED APPLICATION END USER LICENSE AGREEMENT
          </text>
          <text className="privacytext">
            {" "}
            <b>i. </b>Ciencio does not tolerate objectionable content or abusive
            users. Child Sexual Abuse and Exploitation is strictly prohibited.
            You cannot use our service to send spam, scam users, or promote
            violence in any way. We also do not allow you to share explicit
            and/or pornographic content on your publicly viewable profile.
          </text>
          <text className="privacytext">
            Were this to happen, please report immediately. Ciencio has the
            right to access and remove any reported content from the app and
            restrain or deny access to users who have been reported, depending
            on the case.
          </text>
          <text className="privacytext">
            <b>ii. </b>
            While we do not demonize consensed private sharing of pornographic
            images, please be mindful and respectful, especially when contacting
            someone you don't know. If you fail to do so, we may block you from
            your account or take required action.
          </text>
          <text className="privacytext">
            If you make your account public and receive harmful images, please
            report to us as soon as you can.
          </text>
          <text className="privacytext">
            We are aware that these things can happen and will make a human
            effort to handle the situation appropriately.
          </text>
          <text className="privacytext">
            <b>iii. </b>
            Your account is private by default. When you have a private account,
            only people who know your contact information can contact you. If
            you choose to have a public profile, anybody who also has a public
            account can reach you. We have done this to allow people to get to
            know each other in a different way. You are responsible for whom you
            chose to contact and the images you share on Ciencio's platform.
          </text>
          <text className="privacytext">
            <b>iv. </b>
            By using Ciencio, you agree to these Terms of Service. If you have
            any questions, kindly contact us via email.
          </text>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
