import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useUserSettings } from "../user";
import { i18n } from "../i18n";
import { isMobile } from "react-device-detect";
import logo from "./../pics/cienciologo.png";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLOpen, setIsLOpen] = useState(false);
  const [settings, setSettings] = useUserSettings();
  const { language } = settings;

  const setEnglish = () => {
    setSettings({ ...settings, language: "en" });
  };

  const setSpanish = () => {
    setSettings({ ...settings, language: "es" });
  };

  const setFrench = () => {
    setSettings({ ...settings, language: "fr" });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleLanguage = () => {
    setIsLOpen(!isLOpen);
  };

  const Language = () => {
    if (settings.language === "en") {
      return <text>EN ▼</text>;
    }
    if (settings.language === "fr") {
      return <text>FR ▼</text>;
    }
    if (settings.language === "es") {
      return <text>ES ▼</text>;
    }
  };

  const MobileView = () => {
    return (
      <div className="navbarclosedmobile">
        <button onClick={toggle} className="icon">
          <MenuIcon style={{ color: "#fff" }} />
        </button>
        <button
          className="dropdown_button_mobile"
          onClick={() => toggleLanguage()}
        >
          <div className="dr_icon">
            <Language />
          </div>
          {isLOpen ? (
            <div className="languagesmobile">
              {settings.language === "en" ? (
                <button
                  onClick={() => setEnglish()}
                  className="selectedlanguage"
                >
                  English
                </button>
              ) : (
                <button onClick={() => setEnglish()} className="language">
                  English
                </button>
              )}
              {settings.language === "es" ? (
                <button
                  onClick={() => setSpanish()}
                  className="selectedlanguage"
                >
                  Español
                </button>
              ) : (
                <button onClick={() => setSpanish()} className="language">
                  Español
                </button>
              )}
              {settings.language === "fr" ? (
                <button
                  onClick={() => setFrench()}
                  className="selectedlanguage"
                >
                  Français
                </button>
              ) : (
                <button onClick={() => setFrench()} className="language">
                  Français
                </button>
              )}
            </div>
          ) : (
            <></>
          )}
        </button>
        <div>
          <img src={logo} alt="" className="logomobile"></img>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="navbarclosed">
        <button className="home_button">
          <Link className="nav_option" to={"/"} onClick={toggle}>
            {i18n({ index: "home", language })}
          </Link>
        </button>
        <button className="about_button">
          <Link className="nav_option" to={"/about"} onClick={toggle}>
            {i18n({ index: "about", language })}
          </Link>
        </button>
        <button className="download_button">
          <Link className="nav_option" to={"/download"} onClick={toggle}>
            {i18n({ index: "download", language })}
          </Link>
        </button>
        <button className="music_button">
          <Link className="nav_option" to={"/music"} onClick={toggle}>
            <MusicNoteIcon />
          </Link>
        </button>
        <button className="dropdown_button" onClick={() => toggleLanguage()}>
          <div className="dr_icon">
            <Language />
          </div>
          {isLOpen ? (
            <div className="languages">
              {settings.language === "en" ? (
                <button
                  onClick={() => setEnglish()}
                  className="selectedlanguage"
                >
                  English
                </button>
              ) : (
                <button onClick={() => setEnglish()} className="language">
                  English
                </button>
              )}
              {settings.language === "es" ? (
                <button
                  onClick={() => setSpanish()}
                  className="selectedlanguage"
                >
                  Español
                </button>
              ) : (
                <button onClick={() => setSpanish()} className="language">
                  Español
                </button>
              )}
              {settings.language === "fr" ? (
                <button
                  onClick={() => setFrench()}
                  className="selectedlanguage"
                >
                  Français
                </button>
              ) : (
                <button onClick={() => setFrench()} className="language">
                  Français
                </button>
              )}
            </div>
          ) : (
            <></>
          )}
        </button>
        <div>
          <img src={logo} alt="" className="logonuevo"></img>
        </div>
        {/* <img src={logo} alt="" className="logonavclosed"></img> */}
      </div>
    );
  };

  return isOpen && isMobile ? (
    <div className="navbaropen">
      <div className="options">
        <button onClick={() => toggle()} className="icon">
          <CloseIcon style={{ color: "#fff" }} />
        </button>
        <Link className="option" to={"/"} onClick={toggle}>
          {i18n({ index: "home", language })}
        </Link>
        <Link className="option" to={"/about"} onClick={toggle}>
          {i18n({ index: "about", language })}
        </Link>
        <Link className="option" to={"/download"} onClick={toggle}>
          {i18n({ index: "download", language })}
        </Link>
        <Link className="option" to={"/music"} onClick={toggle}>
          {i18n({ index: "music", language })}
        </Link>
      </div>
    </div>
  ) : isMobile ? (
    <MobileView />
  ) : (
    <DesktopView />
  );
};
