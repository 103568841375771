import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

export const Privacy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MobileView = () => {
    return (
      <div className="background_2">
        <div className="privacydiv_mobile">
          <h3>Privacy Policy</h3>
          <text className="privacytext">
            {" "}
            <b>i. </b>
            Ciencio does not collet data from users and uses standard
            encryption.
          </text>
          <text className="privacytext">
            <b>ii. </b>
            Basic user information such as username, email and password are
            required solely for basic credential purposes and to ensure adecuate
            use of our app.
          </text>

          <text className="privacytext">
            <b>iii. </b>
            Ciencio also needs access to your Camera and Photos so that you
            could take pictures or send the ones you already have to your
            contacts.
          </text>
          <text className="privacytext">
            {" "}
            <b>iv. </b>If you have any quesions, kindly contact us via email.
          </text>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="background_2">
        <div className="privacydiv">
          <h3>Privacy Policy</h3>
          <text className="privacytext">
            {" "}
            <b>i. </b>
            Ciencio does not collet data from users and uses standard
            encryption.
          </text>
          <text className="privacytext">
            <b>ii. </b>
            Basic user information such as username, email and password are
            required solely for basic credential purposes and to ensure adecuate
            use of our app.
          </text>

          <text className="privacytext">
            <b>iii. </b>
            Ciencio also needs access to your Camera and Photos so that you
            could take pictures or send the ones you already have to your
            contacts.
          </text>
          <text className="privacytext">
            {" "}
            <b>iv. </b>If you have any quesions, kindly contact us via email.
          </text>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
